import { useEffect, useState } from "react";
import { Input, Slider } from "../Library/Module";
import { GetSearchTerm } from "../servies/services";
import { objectToQueryString } from "../Library/Utility/Utility";
import { NavLink } from "react-router-dom";
import thumbnail from "../images/thumbnail.jpg"

const SearchContainer = ({ CloseDrawer }: any): JSX.Element => {
    const [formData, setFormData] = useState<any>({});
    const [searchResult, setSearchResult] = useState<any>({})
    const [searchLoading, setSearchLoading] = useState(false)
    const onChangeSingleCallback = (data: any) => {
        setFormData((prevState: any) => ({
            ...prevState,
            ...data,
        }));
    };

    useEffect(() => {
        if (formData?.search_term?.length > 2) {
            setSearchLoading(true)
            const query = {
                keyword: formData?.search_term
            }
            const getData = async () => {
                const APIResponse = await GetSearchTerm(objectToQueryString(query));
                console.log("APIResponse", APIResponse)
                setSearchResult(APIResponse?.data?.data)
                setSearchLoading(false)
            }
            getData()
        } else {
            setSearchResult({})
            setSearchLoading(false)
        }
    }, [formData?.search_term])

    const ElementBox = ({ data, type, isLoading }: any) => {
        console.log("data --------", data)
        const link = type === 'ProductCatalog' ?
            `/category/${data?.category}/${data?.slug}` :
            type === 'ProductCollection' ?
                `/category/${data?.category}/${data?.catalog}` :
                `/category/${data?.slug}`
        return (
            <>
                <div className="col-md-4 mb-3">
                    <div className="inner skeleton">
                        <h3>{isLoading ? <div className="skeleton-header"></div> : data?.title}</h3>
                        <div className="placeholderImage">
                            {isLoading ? <div className="skeleton-body"></div> :
                                <img src={data?.image?.length === 0 ? thumbnail : process.env.react_app_base_url + "/" + data?.image?.[0]} title="" alt="" />
                            }
                        </div>
                        {isLoading ? <div className="skeleton-body"></div> :
                            <NavLink onClick={() => CloseDrawer()} className={'btn btn-primary-outlined'} to={link}>View Details</NavLink>
                        }
                    </div>
                </div>
            </>

        )
    }

    return (
        <>
            <div className="searchInner">
                <Input
                    inputName="search_term"
                    inputType="text"
                    labelName={"Search Term"}
                    placeholder="Please enter your search terms"
                    required={true}
                    onChangeSingleCallback={onChangeSingleCallback}
                    col="12"
                    value={formData?.search_term}
                />
                {(formData?.search_term === undefined || formData?.search_term?.length < 2) && <div className="note">Please enter your search terms</div>}

                {searchLoading &&
                    <ElementBox type={'ProductCategory'} isLoading={true} data={{}}></ElementBox>
                }
                {searchResult?.ProductCategory?.length === 0 &&
                    searchResult?.ProductCatalog?.length === 0 &&
                    searchResult?.ProductCollection?.length === 0 && <>
                        <div className="note">No Result Found</div>
                    </>}
                <div className="row">
                    {searchResult?.ProductCategory && searchResult?.ProductCategory?.map((data: any) => {
                        return (
                            <ElementBox type={'ProductCategory'} data={data}></ElementBox>
                        )
                    })}
                    {searchResult?.ProductCatalog && searchResult?.ProductCatalog?.map((data: any) => {
                        return (
                            <ElementBox type={'ProductCatalog'} data={data}></ElementBox>
                        )
                    })}
                    {searchResult?.ProductCollection && searchResult?.ProductCollection?.map((data: any) => {
                        return (
                            <ElementBox type={'ProductCollection'} data={data}></ElementBox>
                        )
                    })}
                </div>
            </div>
        </>
    );
};
export default SearchContainer;
